import {Component, HostListener, Inject, OnInit, Sanitizer, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PdfJsViewerComponent} from "ng2-pdfjs-viewer/src/ng2-pdfjs-viewer.component";
import {AppMobileService} from "../../../../services/app.mobile.service";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {File} from "../../../../models/file";

@Component({
  templateUrl: 'shared.dialogs.content-viewer.component.html',
  styleUrls: ['shared.dialogs.content-viewer.component.scss']
})
export class SharedDialogsContentViewerComponent implements OnInit {
  public static PREVIEW_MIMETYPES = [
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/webp'
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.show_pdf_viewer) {
      this.updateHeight();
    }
  }


  fileUrl: any;


  pdfBlob: any;

  image: any;

  show_pdf_viewer = false;

  isMobile = false;

  @ViewChild('pdfViewer') pdfViewer: PdfJsViewerComponent;
  @ViewChild('pdfviewerdiv') pdfviewerdiv: any;

  _onDestroy = new Subject();

  loading = new BehaviorSubject(false);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { file: File, blob: Blob }, private msvc: AppMobileService,
              private sanitizer: DomSanitizer, private ref: MatDialogRef<SharedDialogsContentViewerComponent>) {

  }

  close() {
    this.ref.close(true);
  }

  ngOnInit(): void {
    this.msvc.isMobile().pipe(takeUntil(this._onDestroy)).subscribe(m => this.isMobile = m);
    this.dlFile();
  }

  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  setFileUrl(url) {
    this.fileUrl = url;


  }
  loadImage() {
      let imgurl = URL.createObjectURL(this.data.blob);
      this.image = this.sanitizer.bypassSecurityTrustUrl(imgurl);
  }

  downloadImage() {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(this.data.blob);
    a.target = '_blank';
    a.download = this.data.file.filename+'.'+this.data.file.extension;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  loadPdf() {
    this.pdfBlob = URL.createObjectURL(this.data.blob);
    this.showPdf();
  }

  showPdf() {
    this.show_pdf_viewer = true;

    setTimeout(() => {
      this.pdfViewer.pdfSrc = this.pdfBlob; // pdfSrc can be Blob or Uint8Array
      this.pdfViewer.downloadFileName = this.data.file.filename + '.' + this.data.file.extension;
      this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf

      this.updateHeight();
    }, 200);
  }

  private updateHeight() {
    if (this.pdfviewerdiv) {
      let height: string | number;
      if (this.isMobile) {
        height = window.innerHeight - this.pdfviewerdiv.nativeElement.offsetTop - 200;
      } else {
        height = window.innerHeight - this.pdfviewerdiv.nativeElement.offsetTop - 280;
      }

      this.pdfviewerdiv.nativeElement.style.height = height + 'px';
    } else {
      setTimeout(() => {
        this.updateHeight();
      }, 100);
    }
  }

  dlFile() {
    if (this.data.file.mimetype == 'application/pdf') {
      this.loadPdf();
    } else {
      this.loadImage();
    }
  }


}
