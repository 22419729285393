import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppPageTitleService} from '../../services/app.page-title.service';
import {AppLieferkundeService} from '../../services/app.lieferkunde.service';
import {Lieferkunde} from '../../models/lieferkunde';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AppEventService} from '../../services/app.event.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SectionTitleButton} from '../../modules/shared/models/section-title-button';
import {DomainConfig} from '../../models/domain-config';
import {AppConfigService} from '../../services/app.config.service';
import {AppMemoryService} from '../../services/app.memory.service';
import {AppUserService} from "../../services/app.user.service";
import {User} from "../../models/user";
import {AppKeyValueStorageService} from "../../services/app.key-value-storage.service";
import {AppDialogsService} from "../../services/app.dialogs.service";
import {Logger} from "../../services/app.logger.service";
import {AppNavbarService} from "../../services/app.navbar.service";
import {AppAsyncTimeout} from "../../helpers/app.asyncTimeout";
import {LieferkundeGroup} from "../../models/lieferkunde-group";

@Component({
  templateUrl: 'app.lieferkunde-selector.component.html',
  styleUrls: ['app.lieferkunde-selector.component.scss']
})
export class AppLieferkundeSelectorComponent implements OnInit, OnDestroy {
  lieferkundenGroups_filtered: LieferkundeGroup[];
  current_lieferkunde: Lieferkunde;

  remember_lknd_nr = false;

  autologon_lknd_nr: any = 0;

  search = '';

  show = new BehaviorSubject(false);
  autoSelecting = new BehaviorSubject<Lieferkunde | null>(null);

  loading = false;
  selecting = false;

  current_differential_lieferkunde: Lieferkunde;

  lksub: Subscription;
  esub: Subscription;

  domaincfg: DomainConfig;

  section_title_buttons: SectionTitleButton[] = [
    {
      icon: 'neustart',
      label: null,
      title: 'Neu Laden',
      class: null,
      onclick: () => {
        this.filter();
      }
    }
  ];

  rsub: Subscription;

  user: User;
  usub: Subscription;

  search_dirty = false;

  differential_mode = false;

  selectRetryCounter = 0;

  initDone = false;

  constructor(private route: ActivatedRoute, private svc: AppLieferkundeService, private tsvc: AppPageTitleService, private esvc: AppEventService,
              private loader: AppPageloaderService, private router: Router, private cfg: AppConfigService, private memsvc: AppMemoryService,
              public usvc: AppUserService, private kvstorage: AppKeyValueStorageService, private dsvc: AppDialogsService,
              private nbsvc: AppNavbarService) {
    this.domaincfg = cfg.domainconfig;
  }

  saveSettings(lkndnr) {
    if (!this.differential_mode) {
      if (this.remember_lknd_nr) {
        this.kvstorage.save4User('autologon_lkndnr', lkndnr).subscribe();
      } else {
        this.kvstorage.save4User('autologon_lkndnr', 0).subscribe();
      }
    }
  }

  loadSettings(execAfter) {
    this.kvstorage.load4User('autologon_lkndnr').subscribe(lkdnrnr => {
      if (lkdnrnr) {
        this.autologon_lknd_nr = lkdnrnr;
        this.remember_lknd_nr = true;
        execAfter();
      } else {
        this.autologon_lknd_nr = 0;
        this.remember_lknd_nr = false;
        execAfter();
      }
    });
  }

  filter(no_autologon_search = false) {
    if (!this.loading && !this.selecting && !this.user.vertreter || (this.user.vertreter && this.search != "")) {
      this.loading = true;
      this.loader.start('AppLieferkundeSelectorComponent_1');

      let search = this.search;

      if (this.svc.isLogin && this.autologon_lknd_nr && this.autologon_lknd_nr > 0 && !no_autologon_search) {
        search = this.autologon_lknd_nr;
        Logger.debug('LieferkundeSelector', 'filter', 'Suche per autologon_lknd_nr '+search);
      }

      Logger.debug('LieferkundeSelector', 'filter', 'Suche per '+search);

      this.svc.search(search, false, 20).subscribe({
        next: (lks) => {
          let retryWithNoAutologon = false;
          if (this.svc.isLogin && lks.length == 0 && this.autologon_lknd_nr && this.autologon_lknd_nr > 0 && !no_autologon_search) {
            Logger.debug('LieferkundeSelector', 'filter', 'Suche keine Treffer - Suche ohne autologon_lknd_nr');
            this.loading = false;
            retryWithNoAutologon = true;

          } else {
            if (this.differential_mode) {
              Logger.debug('LieferkundeSelector', 'filter', 'differential_mode');
              if (this.domaincfg.differential_lieferkunde_regex_nachname != "" || this.domaincfg.differential_lieferkunde_regex_vorname != "") {
                Logger.debug('LieferkundeSelector', 'filter', 'differential_mode Filter vorhanden');
                let lksFiltered = [];

                let regexp_vn;
                let regexp_nn;
                if (this.domaincfg.differential_lieferkunde_regex_vorname) {
                  regexp_vn = new RegExp(this.domaincfg.differential_lieferkunde_regex_vorname);
                }
                if (this.domaincfg.differential_lieferkunde_regex_nachname) {
                  regexp_nn = new RegExp(this.domaincfg.differential_lieferkunde_regex_nachname);
                }

                lks.forEach(lk => {
                  if (!lk.Vorname) {
                    lk.Vorname = '';
                  }
                  if (!lk.Nachname) {
                    lk.Nachname = '';
                  }
                  let valid = true;
                  if (regexp_vn && !lk.Vorname.match(regexp_vn)) {
                    valid = false;

                  } else if (regexp_nn && !lk.Nachname.match(regexp_nn)) {
                    valid = false;

                  }
                  if (valid) {
                    lksFiltered.push(lk);
                  }
                });


                this.lieferkundenGroups_filtered = this.groupLieferkunden(lksFiltered);


              } else {
                Logger.debug('LieferkundeSelector', 'filter', 'differential_mode Filter nicht vorhanden');
                this.lieferkundenGroups_filtered = this.groupLieferkunden(lks);
              }


            } else {
              if (this.domaincfg.differential_lieferkunde_regex_nachname != "" || this.domaincfg.differential_lieferkunde_regex_vorname != "") {
                Logger.debug('LieferkundeSelector', 'filter', 'nicht differential_mode filter vorhanden');
                let lksFiltered = [];
                let regexp_vn;
                let regexp_nn;
                if (this.domaincfg.differential_lieferkunde_regex_vorname) {
                  regexp_vn = new RegExp(this.domaincfg.differential_lieferkunde_regex_vorname);
                }
                if (this.domaincfg.differential_lieferkunde_regex_nachname) {
                  regexp_nn = new RegExp(this.domaincfg.differential_lieferkunde_regex_nachname);
                }

                lks.forEach(lk => {
                  if (!lk.Vorname) {
                    lk.Vorname = '';
                  }
                  if (!lk.Nachname) {
                    lk.Nachname = '';
                  }
                  let valid = true;
                  if (regexp_vn && lk.Vorname.match(regexp_vn)) {
                    valid = false;

                  } else if (regexp_nn && lk.Nachname.match(regexp_nn)) {
                    valid = false;

                  }
                  if (valid) {
                    lksFiltered.push(lk);
                  }
                });


                if (this.svc.isLogin && this.autologon_lknd_nr && this.autologon_lknd_nr > 0 && !no_autologon_search) {
                  if (lksFiltered.length == 0) {
                    Logger.debug('LieferkundeSelector', 'filter', 'nicht differential_mode filter vorhanden -> AutoLogin verwendet difflk nummer');
                    retryWithNoAutologon = true;
                  }
                }

                this.lieferkundenGroups_filtered = this.groupLieferkunden(lksFiltered);

              } else {
                Logger.debug('LieferkundeSelector', 'filter', 'nicht differential_mode filter nicht vorhanden');
                this.lieferkundenGroups_filtered = this.groupLieferkunden(lks);
              }
            }


            if (lks.length == 1) {
              Logger.debug('LieferkundeSelector', 'filter', 'Nur ein LK gefunden -> wird als autologon_lknd_nr genutzt');
              this.autologon_lknd_nr = lks[0].LieferkundeNr;
            }

            if (this.autologon_lknd_nr && this.autologon_lknd_nr > 0) {
              let login_lk: Lieferkunde = null;
              this.lieferkundenGroups_filtered.some(g => {
                g.lieferkunden.some(lk => {
                  if (lk.LieferkundeNr == this.autologon_lknd_nr) {
                    login_lk = lk;
                    Logger.debug('LieferkundeSelector', 'filter', 'Auto Login LK gefunden');
                    return true;
                  } else {
                    return false;
                  }
                });

                if (login_lk) {
                  return true;
                } else {
                  return false;
                }
              });


              if (login_lk && !this.current_lieferkunde) {
                Logger.debug('LieferkundeSelector', 'filter', 'Auto Login LK gestartet');
                this.autoSelecting.next(login_lk);
                Logger.debug('LieferkundeSelector', 'filter', 'autoSelecting = '+login_lk.LieferkundeNr);
                Logger.debug('LieferkundeSelector', 'filter', 'show = false');
                this.show.next(false);
                this.doSelect(login_lk, true);

              } else {
                Logger.debug('LieferkundeSelector', 'filter', 'show = true');
                this.show.next(true);
              }

            } else {
              Logger.debug('LieferkundeSelector', 'filter', 'show = true');
              this.show.next(true);
            }


            this.loader.stop('AppLieferkundeSelectorComponent_1');
            this.loading = false;
          }
          if (retryWithNoAutologon) {
            Logger.debug('LieferkundeSelector', 'filter', 'retryWithNoAutologon');
            this.loading = false;
            this.filter(true);
          }
        }, error: () => {
          this.loader.stop('AppLieferkundeSelectorComponent_1');
          this.loading = false;
        }
      });
    } else if (this.user.vertreter && this.search == "") {
      Logger.debug('LieferkundeSelector', 'filter', 'Suche geblockt');
      this.lieferkundenGroups_filtered = undefined;
      Logger.debug('LieferkundeSelector', 'filter', 'show = true');
      this.show.next(true);
    }
  }

  private groupLieferkunden(lks: Lieferkunde[]) {
    let map = {};
    lks.forEach(lk => {
      if (!lk.a3KundeNr) {
        lk.a3KundeNr = 0;
      }
      if (!map[lk.a3KundeNr]) {
        let grp = new LieferkundeGroup();
        grp.a3KundeNr = lk.a3KundeNr;
        grp.label = lk.a3KundeLabel;
        map[lk.a3KundeNr] = grp;
      }
      map[lk.a3KundeNr].lieferkunden.push(lk);
    })

    let list = [];
    Object.keys(map).forEach(kndnr => {
      list.push(map[kndnr]);
    });

    return list;
  }

  ngOnInit(): void {
    this.nbsvc.setDisabled(true);
    this.usub = this.usvc.user$.subscribe(u => {
      this.loadSettings(() => {
        if (!this.svc.current_lieferkunde.value) {
          Logger.debug('LieferkundeSelector', 'ngOnInit', 'Lieferkunde nicht gesetzt');
          this.svc.init(true).subscribe(() => {
            Logger.debug('LieferkundeSelector', 'ngOnInit', 'LieferkundeService init done');
            this.initDone = true;
            this.filter();
          });
        } else {
          Logger.debug('LieferkundeSelector', 'ngOnInit', 'Lieferkunde gesetzt');
          this.initDone = true;
          this.filter();
        }
      });
      this.user = u;
      if (this.user.vertreter) {
        this.tsvc.setTitle('Kunde auswählen');
      } else {
        this.tsvc.setTitle('Lieferkunde auswählen');
      }
    });

    this.lksub = this.svc.current_lieferkunde$.subscribe(lk => {
      this.current_lieferkunde = lk;
    });

    /* WIRD PER CURRENT LK bei on Change getriggert...
    this.esub = this.esvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\LieferkundeEngine\\LieferkundeChangedEvent') {
        this.filter();
      }
    });*/

    this.rsub = this.route.data.subscribe(d => {
      if (d['differential_mode'] && d['differential_mode'] == true) {
        this.differential_mode = true;

      } else {
        this.differential_mode = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.nbsvc.setDisabled(false);
    if (this.memsvc.has('lieferkunde-selector_redirurl')) {
      this.memsvc.delete('lieferkunde-selector_redirurl');
    }
    if (this.memsvc.has('target_before_login_redirect')) {
      this.memsvc.delete('target_before_login_redirect');
    }
    this.tsvc.resetTitle();
    if (this.lksub instanceof Subscription) {
      this.lksub.unsubscribe();
    }
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }
    if (this.rsub instanceof Subscription) {
      this.rsub.unsubscribe();
    }
    if (this.usub instanceof Subscription) {
      this.usub.unsubscribe();
    }
  }

  tryContinueSelect(state, lk, exec_after_ok) {
    if (!state) console.error('lieferkunde - init failed!');
    let error = false;
    if (!state) {
      error = false;
    }

    if (!lk) {
      error = true;

    } else {
      if (this.differential_mode) {
        if (this.svc.current_differential_lieferkunde.value && this.svc.current_differential_lieferkunde.value.LieferkundeNr != lk.LieferkundeNr) {
          error = true;
        }
      } else {
        if (this.svc.current_lieferkunde.value && this.svc.current_lieferkunde.value.LieferkundeNr != lk.LieferkundeNr) {
          error = true;
        }
      }
    }

    if (error) {
      if (this.selectRetryCounter > 5) {
        this.doSelect(lk, true);

      } else if (this.selectRetryCounter > 10) {
        this.dsvc.info('error', 'Es ist ein Fehler aufgetreten!', 'Applikation muss neugestartet werden!', () => {
          window.location.reload();
        });

      } else {
        this.selectRetryCounter++;
        AppAsyncTimeout.setTimeout(() => {
          this.tryContinueSelect(state, lk, exec_after_ok);
        }, 50);

      }
    } else {
      this.selectRetryCounter = 0;
      exec_after_ok();
    }
  }

  doSelect(lk: Lieferkunde, force = false) {
    if ((!this.loading && !this.selecting) || force) {
      if (this.differential_mode) {
        Logger.debug('LieferkundeSelector', 'doSelect', 'Start differential_mode');
      } else {
        Logger.debug('LieferkundeSelector', 'doSelect', 'Start');
      }
      this.loading = true;
      this.selecting = true;
      this.loader.start('AppLieferkundeSelectorComponent_2');
      this.svc.set(lk, this.differential_mode).subscribe({
        next: (state) => {
          this.saveSettings(lk.LieferkundeNr);

          Logger.debug('LieferkundeSelector', 'doSelect', 'init done');
          this.tryContinueSelect(state, lk, () => {
            Logger.debug('LieferkundeSelector', 'doSelect', 'Lieferkunde korrekt gesetzt!');
            //OBSOLET this.esvc.addGuiEvent('lieferKundeSelected');
            this.loading = false;
            let redirurl = this.memsvc.get('lieferkunde-selector_redirurl');
            this.memsvc.delete('lieferkunde-selector_redirurl');

            if (!redirurl) {
              redirurl = localStorage.getItem('target_before_login_redirect');
              if (redirurl) {
                localStorage.removeItem('target_before_login_redirect');
              }
            }

            if (!redirurl || redirurl == '/login' || redirurl == '/logout') {
              redirurl = '/';
            }

            Logger.debug('LieferkundeSelector', 'doSelect', 'delay redirect for 200ms');

            this.autoSelecting.next(null);
            Logger.debug('LieferkundeSelector', 'filter', 'autoSelecting = null');
            Logger.info('LieferkundeSelector', 'doSelect', "Redirecting to " + redirurl);

            this.router.navigate([redirurl], {replaceUrl: true});

          });

        }, error: (e) => {
          this.loading = false;
          this.loader.stop('AppLieferkundeSelectorComponent_2');
          Logger.debug('LieferkundeSelector', 'doSelect', 'Error!');
        }
      });
    }
  }


}
